var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"subject-detail"}},[_c('defaultTemplate',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[(_vm.badgeData)?_c('div',{staticClass:"pd-x-5 pd-y-3"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: "/subject" }}},[_c('span',{staticClass:"text-all font-12"},[_vm._v("My Course")])]),_c('el-breadcrumb-item',[_c('span',{staticClass:"font-12 text-breadcrumb"},[_vm._v(_vm._s(_vm.badgeData.courseName)+" ("+_vm._s(_vm.badgeData.courseId)+")")])])],1),_c('div',{staticClass:"mg-t-2"},[_c('CardSubject',{attrs:{"nowHour":_vm.badgeData.obtainedLearningHour,"totalHour":_vm.badgeData.learningHour,"percentNow":_vm.percentHour(
              _vm.badgeData.obtainedLearningHour,
              _vm.badgeData.learningHour
            ) > 100
              ? 100
              : _vm.percentHour(
                  _vm.badgeData.obtainedLearningHour,
                  _vm.badgeData.learningHour
                ),"favorite":_vm.badgeData.favorite,"strokeWidth":15,"nameSubject":_vm.badgeData.courseName,"bgProgress":"light","courseId":_vm.badgeData.courseId,"subjectId":_vm.badgeData._id},on:{"updateStatus":_vm.updateStatus}})],1),(_vm.groupType != null)?_c('div',[_c('div',{staticClass:"is-flex js-between border-b pd-t-5"},[_c('p',{staticClass:"font-16 text-dark pd-t-5 font-weight-500"},[_vm._v(" "+_vm._s("Badge " + _vm.badgeData.courseId)+" ")]),_c('p',{staticClass:"font-14 color-70 pd-t-5"},[_vm._v(" "+_vm._s(_vm.groupType.length > 1 ? " Badges" : " Badge")+" ")])]),_c('div',{staticClass:"mg-y-5"}),_c('carousel',{attrs:{"responsive":_vm.responsive,"nav":false}},_vm._l((_vm.groupType),function(badge,index){return _c('div',{key:index},[_c('div',{staticClass:"card-badge mg-6 cursor-pointer",class:_vm.selectBadge.skillType == badge.skillType ? 'active' : 'graph',on:{"click":function($event){return _vm.getBadgeDetail(badge)}}},[_c('div',{staticClass:"is-flex ai-center fix-height-40"},[_c('i',{staticClass:"el-icon-success color-blue"}),(_vm.sumLearning(badge) > 1)?_c('p',{staticClass:"font-12 mg-l-7 font-weight-500 color-blue-dark"},[_c('span',[_vm._v(" "+_vm._s(_vm.sumObtained(badge)))]),_vm._v(_vm._s("/" + badge.detailSkill.minimum + " Learning hours")+" ")]):_c('p',{staticClass:"font-12 mg-l-7 font-weight-500 color-blue-dark"},[_c('span',[_vm._v(" "+_vm._s(_vm.sumObtained(badge)))]),_vm._v(_vm._s("/" + badge.detailSkill.minimum + " Learning hour")+" ")])]),_c('div',{staticClass:"mg-y-5"},[_c('CirclePrecess',{attrs:{"percentage":_vm.percentHour(
                      _vm.sumObtained(badge),
                      badge.detailSkill.minimum
                    ) > 100
                      ? 100
                      : _vm.percentHour(
                          _vm.sumObtained(badge),
                          badge.detailSkill.minimum
                        ),"color":"#084e98","strokewidth":7,"width":80}})],1),_c('p',{staticClass:"mg-b-7 font-12 font-weight-500 color-blue-dark"},[_vm._v(" "+_vm._s(badge.courseId)+"."+_vm._s(badge.skillType)+" ")])])])}),0),_c('div',[_c('div',{staticClass:"is-flex js-between ai-end pd-t-5"},[_c('p',{staticClass:"font-16 text-dark pd-t-5 font-weight-500 mg-b-0"},[_vm._v(" "+_vm._s(_vm.selectBadge.courseId)+"."+_vm._s(_vm.selectBadge.skillType)+" "),_c('span',{staticClass:"font-12 color-blue-light"},[_vm._v("("+_vm._s(_vm.sumObtained(_vm.selectBadge) + "/" + _vm.selectBadge.detailSkill.minimum)+" "+_vm._s(_vm.selectBadge.detailSkill.minimum > 1 ? "Learning hours" : "Learning hour")+")")])]),_c('div',{staticClass:"color-blue-light cursor-pointer",on:{"click":function($event){_vm.showFilter = !_vm.showFilter}}},[_c('i',{staticClass:"fas fa-filter"}),_vm._v(" Filter ")])]),_c('el-divider',{staticClass:"mg-y-5"}),(_vm.showFilter)?_c('div',{staticClass:"filter--checkbox"},[_c('el-checkbox-group',{attrs:{"size":"mini"},on:{"change":_vm.filterTags},model:{value:(_vm.tagFilter),callback:function ($$v) {_vm.tagFilter=$$v},expression:"tagFilter"}},[_c('el-row',{attrs:{"gutter":12}},_vm._l((_vm.filterText),function(data,index){return _c('el-col',{key:index,staticClass:"is-flex",attrs:{"span":8,"offset":0}},[_c('el-checkbox-button',{staticClass:"w-100",class:data.color,attrs:{"label":data.value}},[_vm._v(_vm._s(data.label))])],1)}),1)],1)],1):_vm._e()],1)],1):_c('div',{staticClass:"text-center mg-t-3"},[_c('p',[_vm._v("ไม่พบข้อมูล Badge")])]),(_vm.selectBadge != null && _vm.selectBadge.data.length > 0)?_c('swipe-list',{ref:"list",attrs:{"items":_vm.selectBadge.data,"item-key":"id","item-disabled":function (e) {
            return _vm.funcsss(e);
          }},on:{"swipeout:click":_vm.itemClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
return [(item.active)?_c('div',{staticClass:"card-badge cursor-pointer",class:item.status == 0
                ? 'unbooked'
                : item.status == 1
                ? 'upcomming'
                : item.status == 2
                ? 'complete'
                : 'missing'},[_c('div',{},[_c('div',{staticClass:"is-flex js-between ai-center"},[_c('div',{staticClass:"font-12 mg-y-0 title-badge is-flex ai-center"},[_c('span',{staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap","max-width":"214px","display":"inline-block"}},[_vm._v(" "+_vm._s(item.topic)+" ")]),_c('div',{staticClass:"badge-tag mg-x-6"},[_vm._v(" "+_vm._s(item.status == 0 ? "Unbooked" : item.status == 1 ? "Upcomming" : item.status == 2 ? "Complete" : "Missing")+" ")])]),_c('p',{staticClass:"font-12 mg-y-0 color-blue-dark"},[_c('i',{staticClass:"fas fa-chevron-right"})])]),_c('div',{staticClass:"name-sub"},[_c('p',{staticClass:"mg-0 mg-t-7 font-10"},[_vm._v(" "+_vm._s(item.obtainedLearningHour != null ? item.obtainedLearningHour : 0)+"/"+_vm._s(item.learningHour)+" "+_vm._s(item.learningHour > 1 ? "Learning hours" : "Learning hour")+" ")])])])]):_vm._e()]}},{key:"right",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"box-swipe cursor-pointer"},[_c('div',{staticClass:"swipe-delete",on:{"click":function($event){return _vm.openModalSession(item)}}},[_c('i',{staticClass:"fas fa-trash-alt"}),_vm._v(" Delete ")])])]}}],null,false,1892342622)}):_c('div',{staticClass:"text-center mg-t-2"},[_c('p',[_vm._v("ไม่พบข้อมูล Badge")])]),(_vm.confirmDialog)?_c('el-dialog',{attrs:{"visible":_vm.confirmDialog,"width":"98%","center":"","destroy-on-close":true},on:{"update:visible":function($event){_vm.confirmDialog=$event}}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v("คุณแน่ใจว่าจะยกเลิก Session นี้?")]),_c('p',[_vm._v("Session: "+_vm._s(_vm.badgeEvent.topic))]),_c('p',[_vm._v(_vm._s(_vm.badgeEvent.speakerName))])]),_c('span',{staticClass:"dialog-footer font-12",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"is-flex js-between ai-center"},[_c('a',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.confirmDialog = false}}},[_vm._v("ไม่ใช่ตอนนี้")]),_c('el-button',{staticClass:"font-12 w-auto",attrs:{"type":"primary"},on:{"click":_vm.apiUpdateBooking}},[_vm._v("ยกเลิก Session")])],1)])]):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }