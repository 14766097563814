<template>
  <div id="subject-detail">
    <defaultTemplate v-loading="loading">
      <div class="pd-x-5 pd-y-3" v-if="badgeData">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="">
          <el-breadcrumb-item :to="{ path: `/subject` }">
            <span class="text-all font-12">My Course</span>
          </el-breadcrumb-item>
          <el-breadcrumb-item
            ><span class="font-12 text-breadcrumb"
              >{{ badgeData.courseName }} ({{ badgeData.courseId }})</span
            ></el-breadcrumb-item
          >
        </el-breadcrumb>
        <!-- {{ sumHour(badgeData) }} -->
        <div class="mg-t-2">
          <CardSubject
            @updateStatus="updateStatus"
            :nowHour="badgeData.obtainedLearningHour"
            :totalHour="badgeData.learningHour"
            :percentNow="
              percentHour(
                badgeData.obtainedLearningHour,
                badgeData.learningHour
              ) > 100
                ? 100
                : percentHour(
                    badgeData.obtainedLearningHour,
                    badgeData.learningHour
                  )
            "
            :favorite="badgeData.favorite"
            :strokeWidth="15"
            :nameSubject="badgeData.courseName"
            :bgProgress="`light`"
            :courseId="badgeData.courseId"
            :subjectId="badgeData._id"
          />
        </div>
        <div v-if="groupType != null">
          <div class="is-flex js-between border-b pd-t-5">
            <p class="font-16 text-dark pd-t-5 font-weight-500">
              {{ "Badge " + badgeData.courseId }}
            </p>
            <p class="font-14 color-70 pd-t-5">
              {{ groupType.length > 1 ? " Badges" : " Badge" }}
            </p>
          </div>

          <div class="mg-y-5"></div>

          <carousel :responsive="responsive" :nav="false">
            <div v-for="(badge, index) in groupType" :key="index">
              <div
                class="card-badge mg-6 cursor-pointer"
                @click="getBadgeDetail(badge)"
                :class="
                  selectBadge.skillType == badge.skillType ? 'active' : 'graph'
                "
              >
                <div class="is-flex ai-center fix-height-40">
                  <i class="el-icon-success color-blue"></i>

                  <p
                    class="font-12 mg-l-7 font-weight-500 color-blue-dark"
                    v-if="sumLearning(badge) > 1"
                  >
                    <span> {{ sumObtained(badge) }}</span
                    >{{ "/" + badge.detailSkill.minimum + " Learning hours" }}
                  </p>

                  <p
                    class="font-12 mg-l-7 font-weight-500 color-blue-dark"
                    v-else
                  >
                    <span> {{ sumObtained(badge) }}</span
                    >{{ "/" + badge.detailSkill.minimum + " Learning hour" }}
                  </p>
                </div>

                <div class="mg-y-5">
                  <CirclePrecess
                    :percentage="
                      percentHour(
                        sumObtained(badge),
                        badge.detailSkill.minimum
                      ) > 100
                        ? 100
                        : percentHour(
                            sumObtained(badge),
                            badge.detailSkill.minimum
                          )
                    "
                    :color="`#084e98`"
                    :strokewidth="7"
                    :width="80"
                  />
                </div>

                <p class="mg-b-7 font-12 font-weight-500 color-blue-dark">
                  {{ badge.courseId }}.{{ badge.skillType }}
                </p>
              </div>
            </div>
          </carousel>
          <div>
            <div class="is-flex js-between ai-end pd-t-5">
              <!-- {{ selectBadge }} -->
              <p class="font-16 text-dark pd-t-5 font-weight-500 mg-b-0">
                {{ selectBadge.courseId }}.{{ selectBadge.skillType }}
                <span class="font-12 color-blue-light"
                  >({{
                    sumObtained(selectBadge) +
                    "/" +
                    selectBadge.detailSkill.minimum
                  }}
                  {{
                    selectBadge.detailSkill.minimum > 1
                      ? "Learning hours"
                      : "Learning hour"
                  }})</span
                >
              </p>
              <div
                class="color-blue-light cursor-pointer"
                @click="showFilter = !showFilter"
              >
                <i class="fas fa-filter"></i> Filter
              </div>
            </div>
            <el-divider class="mg-y-5"></el-divider>
            <div class="filter--checkbox" v-if="showFilter">
              <el-checkbox-group
                size="mini"
                v-model="tagFilter"
                @change="filterTags"
              >
                <el-row :gutter="12">
                  <el-col
                    :span="8"
                    :offset="0"
                    v-for="(data, index) in filterText"
                    :key="index"
                    class="is-flex"
                  >
                    <el-checkbox-button
                      :label="data.value"
                      class="w-100"
                      :class="data.color"
                      >{{ data.label }}</el-checkbox-button
                    >
                  </el-col>
                </el-row>
              </el-checkbox-group>
            </div>

            <!-- <el-row :gutter="5" v-if="showFilter">
              <el-col :span="6" v-for="(data, index) in filterText" :key="index"
                ><el-button plain class="font-10" size="mini" type="primary">{{
                  data.label
                }}</el-button></el-col
              >
            </el-row> -->
            <!-- <div v-for="(item, index) in selectBadge.data" :key="index">
              <router-link
                :to="`/subject/${SubjectById}/${item._id}`"
                class="cursor-pointer"
              >
                <div
                  class="card-badge"
                  :class="
                    item.status == 1
                      ? 'upcomming'
                      : item.status == 2
                      ? 'complete'
                      : 'missing'
                  "
                >
                  <div class="">
                    <div class="is-flex js-between ai-center">
                      <div class="font-12 mg-y-0 title-badge is-flex ai-center">
                        <span
                          style="
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            max-width: 214px;
                            display: inline-block;
                          "
                        >
                          {{ item.topic }}
                        </span>

                        <div class="badge-tag mg-x-6">
                          {{
                            item.status == 1
                              ? "Upcomming"
                              : item.status == 2
                              ? "Complete"
                              : "Missing"
                          }}
                        </div>
                      </div>

                      <p class="font-12 mg-y-0 color-blue-dark">
                        <i class="fas fa-chevron-right"></i>
                      </p>
                    </div>

                    <div class="name-sub">
                      <p class="mg-0 mg-t-7 font-10">
                        {{ item.obtainedLearningHour }}/{{ item.learningHour }}
                        {{
                          item.learningHour > 1
                            ? "Learning hours"
                            : "Learning hour"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </router-link>
            </div> -->
          </div>
        </div>
        <div v-else class="text-center mg-t-3">
          <p>ไม่พบข้อมูล Badge</p>
        </div>
        <swipe-list
          ref="list"
          :items="selectBadge.data"
          v-if="selectBadge != null && selectBadge.data.length > 0"
          item-key="id"
          @swipeout:click="itemClick"
          :item-disabled="
            (e) => {
              return funcsss(e);
            }
          "
        >
          <template v-slot="{ item }">
            <div
              class="card-badge cursor-pointer"
              v-if="item.active"
              :class="
                item.status == 0
                  ? 'unbooked'
                  : item.status == 1
                  ? 'upcomming'
                  : item.status == 2
                  ? 'complete'
                  : 'missing'
              "
            >
              <div class="">
                <div class="is-flex js-between ai-center">
                  <div class="font-12 mg-y-0 title-badge is-flex ai-center">
                    <span
                      style="
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-width: 214px;
                        display: inline-block;
                      "
                    >
                      {{ item.topic }}
                    </span>

                    <div class="badge-tag mg-x-6">
                      {{
                        item.status == 0
                          ? "Unbooked"
                          : item.status == 1
                          ? "Upcomming"
                          : item.status == 2
                          ? "Complete"
                          : "Missing"
                      }}
                    </div>
                  </div>

                  <p class="font-12 mg-y-0 color-blue-dark">
                    <i class="fas fa-chevron-right"></i>
                  </p>
                </div>

                <div class="name-sub">
                  <p class="mg-0 mg-t-7 font-10">
                    {{
                      item.obtainedLearningHour != null
                        ? item.obtainedLearningHour
                        : 0
                    }}/{{ item.learningHour }}
                    {{
                      item.learningHour > 1 ? "Learning hours" : "Learning hour"
                    }}
                  </p>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:right="{ item }">
            <div class="box-swipe cursor-pointer">
              <div class="swipe-delete" @click="openModalSession(item)">
                <i class="fas fa-trash-alt"></i> Delete
              </div>
            </div>
          </template>
        </swipe-list>
        <div v-else class="text-center mg-t-2">
          <p>ไม่พบข้อมูล Badge</p>
        </div>
        <el-dialog
          v-if="confirmDialog"
          :visible.sync="confirmDialog"
          width="98%"
          center
          :destroy-on-close="true"
        >
          <div class="text-center">
            <h3>คุณแน่ใจว่าจะยกเลิก Session นี้?</h3>
            <p>Session: {{ badgeEvent.topic }}</p>
            <p>{{ badgeEvent.speakerName }}</p>
          </div>

          <span slot="footer" class="dialog-footer font-12">
            <div class="is-flex js-between ai-center">
              <a @click="confirmDialog = false" class="cursor-pointer"
                >ไม่ใช่ตอนนี้</a
              >
              <el-button
                type="primary"
                @click="apiUpdateBooking"
                class="font-12 w-auto"
                >ยกเลิก Session</el-button
              >
            </div>
          </span>
        </el-dialog>
      </div>
    </defaultTemplate>
  </div>
</template>
<script>
import defaultTemplate from "@/template/default.vue";
import CardSubject from "@/components/CardSubject.vue";
import carousel from "vue-owl-carousel";
import CirclePrecess from "@/components/circleProcess.vue";
import { HTTP } from "@/service/axios";
import { SwipeList, SwipeOut } from "vue-swipe-actions";
import moment from "moment";
import "vue-swipe-actions/dist/vue-swipe-actions.css";
export default {
  components: {
    defaultTemplate,
    CardSubject,
    carousel,
    CirclePrecess,
    SwipeOut,
    SwipeList
  },
  mounted() {
    this.getSubjectById();
    //this.getBadge();
    //this.getBadgeHourSum();
  },
  computed: {
    sumSelectBadge() {
      let data = this.groupType.filter(
        a => a.skillType == this.selectBadge.skillType
      );
      let sumLearning = 0;
      if (data.length > 0) {
        data[0].data.map(lhour => {
          sumLearning = lhour.learningHour + sumLearning;
        });
      }
      return sumLearning;
    },
    user() {
      return this.$store.state.user;
    },
    SubjectById() {
      return this.$route.params.id;
    }
  },
  data() {
    return {
      tagFilter: [1, 2, 3],
      confirmDialog: false,
      badgeEvent: null,
      groupType: null,
      showFilter: false,
      badgeData: null,
      selectBadge: null,
      loading: false,
      responsive: {
        // breakpoint from 0 up
        0: {
          items: 2
          // loop: true,
          // option1 : value,
          // option2 : value,
        },
        // breakpoint from 768 up
        767: {
          items: 2
        }
      },
      filterText: [
        {
          label: "Upcoming",
          color: "upcomming",
          value: 1
        },
        {
          label: "Completed",
          value: 2,
          color: "completed"
        },
        {
          label: "Missing",
          color: "missing",
          value: 3
        }
      ],
      getHoura: 0,
      SemesterId: this.$route.params.idSemester,
      SubjectId: this.$route.params.id,
      activeName: 1,
      badge: "",
      subject: null,
      subjectn: null,
      Semester: null,
      constBadge: []
    };
  },
  methods: {
    funcsss(e) {
      let status = e.status == 1 ? false : true;
      return status;
    },
    apiUpdateBooking() {
      let booking = {
        badgeId: this.badgeEvent.badgeId,
        status: true
      };
      HTTP.defaults.headers.common.Authorization =
        `Bearer ` + this.user.accessToken;
      HTTP.post(`api/book`, booking)
        .then(res => {
          if (!res.data.obj.booking) {
            this.$message({
              message: "คุณได้ถอนการแจ้งเตือนวิชานี้แล้ว",
              type: "warning",
              duration: 3000
            });
            this.confirmDialog = false;
            let index = this.groupType.findIndex(
              a => a.skillType == this.selectBadge.skillType
            );
            console.log(index, "this.groupType", this.groupType);
            this.getSubjectById(index);
          }
        })
        .catch(e => {
          console.log(e);
          this.alertCatchError(e.response.status);
        });
    },
    openModalSession(item) {
      this.badgeEvent = item;
      this.confirmDialog = true;
    },
    revealFirstRight() {
      this.$refs.list.revealRight(0);
    },
    itemClick(e) {
      console.log(e, "item click");
      this.$router.push(`/subject/${this.SubjectById}/${e._id}`);
    },
    filterTags() {
      let constData = JSON.parse(JSON.stringify(this.constBadge));
      let find = this.tagFilter.findIndex(a => a == 0);
      if (find > -1 || this.tagFilter.length == 0) {
        this.selectBadge.data = constData.sort((a, b) => a.status - b.status);
      } else {
        let badge = [];
        this.tagFilter.forEach(item => {
          let filter = constData.filter(a => a.status == item);
          badge = badge.concat(filter);
        });
        this.selectBadge.data = badge.sort((a, b) => a.status - b.status);
      }
    },

    getSubjectById(select = 0) {
      this.groupType = null;
      this.badgeData = null;
      this.selectBadge = null;
      this.loading = true;
      HTTP.defaults.headers.common.Authorization =
        `Bearer ` + this.user.accessToken;
      HTTP.get(`user/subject/${this.SubjectById}`)
        .then(res => {
          if (res.data.status == 200) {
            this.badgeData = res.data.obj;
            let arrType = [];
            this.badgeData.badges.forEach(ft1 => {
              let arrTypeFil = arrType.filter(
                arrFilter => arrFilter.skillType == ft1.skillType
              );
              let getDataSkill = this.badgeData.skillType.filter(
                a => a.type == ft1.skillType
              );
              if (arrTypeFil.length == 0) {
                let badgeFilter = this.badgeData.badges.filter(
                  badgeFil => badgeFil.skillType == ft1.skillType
                );
                let newBadge = [];
                for (let index = 0; index < badgeFilter.length; index++) {
                  let timeEnd = badgeFilter[index].sessionEndTime.split(":");
                  let dateEnd = new Date(
                    moment(badgeFilter[index].sessionDate).format("YYYY"),
                    moment(badgeFilter[index].sessionDate).format("MM") - 1,
                    moment(badgeFilter[index].sessionDate).format("DD"),
                    Number(timeEnd[0]),
                    Number(timeEnd[1]),
                    0,
                    0
                  );
                  let checkBook = badgeFilter[index].book.findIndex(
                    a => a == this.user.id
                  );

                  if (
                    badgeFilter[index].obtainedLearningHour >=
                      badgeFilter[index].learningHour &&
                    dateEnd < new Date()
                  ) {
                    //completed
                    if (badgeFilter[index].learningReport != false) {
                      newBadge.push({
                        ...badgeFilter[index],
                        status: 2,
                        active:
                          badgeFilter[index].learningReport == false
                            ? false
                            : true
                      });
                    }
                  } else if (
                    dateEnd < new Date() &&
                    badgeFilter[index].obtainedLearningHour == 0 &&
                    checkBook > -1
                  ) {
                    //Missing
                    newBadge.push({
                      ...badgeFilter[index],
                      status: 3,
                      active: true
                    });
                  } else if (dateEnd > new Date() && checkBook > -1) {
                    //Upcoming
                    newBadge.push({
                      ...badgeFilter[index],
                      status: 1,
                      active: true
                    });
                  } else {
                    newBadge.push({
                      ...badgeFilter[index],
                      status: 0,
                      active: true
                    });
                  }
                }
                newBadge.sort((a, b) => a.status - b.status);
                let obj = {
                  active: ft1.active,
                  skillType: ft1.skillType,
                  detailSkill: getDataSkill[0],
                  data: newBadge,
                  courseId: ft1.courseId
                };
                if (obj.active) {
                  arrType.push(obj);
                }
              }
            });
            if (arrType.length > 0) {
              this.groupType = arrType;
              this.selectBadge = arrType[select];
              this.constBadge = arrType[select].data;
            }
          }
        })
        .catch(e => {
          console.log(e);
          this.alertCatchError(e.response.status);
        })
        .finally(() => {
          this.loading = false;
          this.filterTags();
        });
    },
    percentHour(nowHour, totalHour) {
      //หน้า * 100 หารหลัง
      if (totalHour != 0) {
        let percentNow = (nowHour * 100) / totalHour;
        return Number(percentNow.toFixed(0));
      }
      return 0;
    },

    sumHour(badgedata) {
      let totalHour = 0;
      if (this.badgeData != null) {
        badgedata.badges.forEach(item => {
          totalHour = item.learningHour + totalHour;
        });
        return totalHour;
      }
    },

    getBadgeDetail(badgeId) {
      this.selectBadge = JSON.parse(JSON.stringify(badgeId));
      this.constBadge = JSON.parse(JSON.stringify(badgeId.data));
      this.tagFilter = [1, 2, 3];
      this.filterTags();
    },

    updateStatus(status) {
      if (status == 1) {
        this.getSubjectById();
      }
    },

    sumObtained(badge) {
      let sumObtained = 0;
      badge.data.map(obt => {
        sumObtained = obt.obtainedLearningHour + sumObtained;
      });
      return sumObtained;
    },

    sumLearning(badge) {
      let sumLearning = 0;
      badge.data.map(lhour => {
        sumLearning = lhour.learningHour + sumLearning;
      });
      return sumLearning;
    }
  }
};
</script>
